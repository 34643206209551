<template>
  <div>
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectLocale',
  data() {
    return { langs: ['de', 'fr'] }
  }
}
</script>

<style scoped>
select {
  padding: 10px;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
  border: 2px solid grey;
  border-radius: 10%;
}
</style>