import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import vueHeadful from 'vue-headful';
import VueSocialSharing from 'vue-social-sharing';
import "./css/custom-vars.scss";
import i18n from './i18n';

Vue.use(VueSocialSharing);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('vue-headful', vueHeadful);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  components: {
    ChartJsPluginDataLabels
  },
  i18n,
  render: h => h(App)
}).$mount("#app");