<template>
  <b-container>
    <div class="text-box">
      <div class="d-flex justify-content-between">
        <h1>{{ $t("home.title") }}</h1>
        <SelectLocale />
      </div>
      <h2>{{ $t("home.subtitle1") }}</h2>
      <p>{{ $t("home.text1") }}</p>
      <h2>{{ $t("home.subtitle2") }}</h2>
      <p>{{ $t("home.text2") }}</p>
      <h2>{{ $t("home.subtitle3") }}</h2>
      <span>{{ $t("home.text3") }}</span>
      <router-link to="annahmen"> {{ $t("home.link") }} </router-link>
      <span>{{ $t("home.text4") }}</span>
      <br>
      <br>
      <b-button :to="{name: 'Questions'}" class="button-secondary">{{ $t("home.button") }}</b-button>
    </div>
  </b-container>
</template>

<script>
import SelectLocale from '../components/SelectLocale.vue'

export default {
  name: "Home",
  components: {
    SelectLocale
  }
};
</script>

<style scoped>
.text-box {
  text-align: left;
}

.lang {
  text-align: right;
}
@media (min-width: 992px) {
  .lang {
    width: 100%;
    margin-bottom: -30px;
  }
}
</style>